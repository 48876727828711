import React from "react"
import { getUser, isLoggedIn, logout } from "../../utils/auth"
import OfficeStatusList from "../OfficeStatusList"

import AppBrand from "../../../static/app-brand.svg";
// <img src={AppBrand}/>

function ShowOfficeStatus() {

  if (isLoggedIn()) {
    return <OfficeStatusList />;
  }
  return '';
}

const Header = () => (
  <header className="site-header">
    <img src={AppBrand}/>
    <ShowOfficeStatus />

  </header>
)

export default Header
