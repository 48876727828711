import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
const OfficeStatusList = () => {
  // Build Time Data Fetching
  // const data = useStaticQuery(graphql`
  //   query {
  //     gcms {
  //       officeses{
  //         officeName
  //         officeStatuses {
  //           statusColor
  //           description
  //         }
  //       }
  //     }
  //   }
  // `)


  const [isActive, updateActive] = useState('false');
  const [officeData, setOfficeData] = useState();
  useEffect(() => {

      fetch('https://api-uswest.graphcms.com/v1/ck69vo8t601qz01939evpfxpx/master', {
        method: "post",
        headers: {
          'Content-Type': 'application/json',
        },
        body: '{"query": "{ officeses {officeName, officeStatuses { statusColor, description }} }"}',
      })
        .then( res => res.json() )
        .then(data => {
          console.log(data);
          setOfficeData(data);
          console.log(officeData);
        })
        .catch(function(error) {
          console.log(error);
        });
  }, [])

if (officeData) {
  return (
    <div className="officeStatusWrap">
    <div className="officeStatusShadow" active={isActive} onClick={() => updateActive('false')}></div>

      <div className="officeStatusListToggle" onClick={() => updateActive('true')}>
          <p>
            <span>
              <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.40848e-07 6.66955C0.00949163 6.37633 0.137627 6.10173 0.360678 5.9109L7.18034 -1.20815e-06L8.59458 1.56848L3.84881 5.68284L21 5.68285L21 7.77726L3.72068 7.77726L8.63729 12.506L7.14237 14L0.322713 7.44215C0.109154 7.23737 -0.00474487 6.95811 6.40848e-07 6.66955Z" fill="#0057FF"/>
              </svg>
            </span>
            Office Statuses
          </p>
      </div>


      <div className="officeStatusList" active={isActive}>
      <div className="closeOfficeStatusList" onClick={() => updateActive('false')}>
        <p>
          <span>
            <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 7.33045C20.9905 7.62367 20.8624 7.89827 20.6393 8.0891L13.8197 14L12.4054 12.4315L17.1512 8.31715H0V6.22274H17.2793L12.3627 1.49402L13.8576 0L20.6773 6.55785C20.8908 6.76263 21.0047 7.04189 21 7.33045Z" fill="#0057FF"/>
            </svg>
          </span>
          Close
        </p>
      </div>
      <p>For those in North America who feel like they need to work in one of our facilities, please fill out this <a className="text-blue-600 hover:text-blue-400 transition duration-300 ease-in-out underline" href={'https://docs.google.com/forms/d/e/1FAIpQLSczSGVBP5HUzFpEM8T-APzfxXb96PTCVkQFlqKwGGqOzz87yA/viewform'} target="_blank">Return to Office Request Form.</a></p>

        {officeData.data.officeses.map(office => {
          const { officeName, officeStatuses } = office
          return (
            <div className="officeStatusName"  key={officeName}>
              <p><span className="officeStatusIndicator" statuscolor={officeStatuses.statusColor}></span>{officeName} - <span>{officeStatuses.description}</span></p>

            </div>

          )
        })}

      </div>
    </div>
  )
} else {
  return ''
}

}
export default OfficeStatusList
