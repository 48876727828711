import React from "react"
import { useState} from "react"
import { Link, navigate } from "@reach/router"
import { getUser, isLoggedIn, logout } from "../../utils/auth"
import { useFirebase } from "gatsby-plugin-firebase"
// import OfficeStatusList from "../OfficeStatusList"

export default () => {
  const [firebase, setFirebase] = useState();

  useFirebase(fb => {
    setFirebase(fb);
  }, [])

  let details;
  if (!isLoggedIn()) {

  } else {
    console.log(getUser());
    const { displayName, photoURL } = getUser()
    details = (
      <nav className="loggedInNav">
        <div>
          <div className="profileObect">
            { photoURL &&
              <img className="profileImage" src={photoURL} alt={displayName} />
            }
            <p className="profileName">{displayName}</p>
          </div>
          <div className="loggedInNavMenu">
            <Link to="/app/home">Home</Link>
            <Link to="/app/xi-state-of-mind">XI State of Mind</Link>
            <Link to="/app/resources">Resources</Link>
            <Link to="/app/news">News</Link>
            <Link to="/app/resources/technology">Technology</Link>
            <Link to="/app/resources/comms-archive">Comms Archive</Link>
          </div>
        </div>
        <div className="sidebarFooterLinks">

          <a className="btn btn-marine w-full mb-4" href="https://inmoment.com/websurvey/2/begin?gateway=InMomentVoE" target="_blank">Feedback</a>
          <a className="btn btn-dark LogoutBtn w-full" href="/" onClick={event => { event.preventDefault(); logout(firebase).then(() => navigate(`/app/login`)) }}>
            Log Out
          </a>
        </div>

      </nav>
    )
  }

  return <div className="loggedInSidebar">{details}</div>
}

// <Link to="/app/news">News</Link>
// <a href="https://inmoment.lessonly.com/signin" target="_blank">Training</a>
