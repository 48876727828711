import React from "react"
import { Helmet } from "react-helmet"
import Status from "../../components/Status"
import Header from "../Header"
import favicon from "../../../static/inmoment-icon.png";

// Global styles and component-specific styles.
import "./global.css"
import styles from "./main.module.css"

const Layout = ({ children }) => (
  <div>
    <Helmet>
      <title>Better Together</title>
      <link rel="icon" sizes="72x72" href={favicon} />
    </Helmet>
    <Header />
    <Status />
    <main className={styles.main}>{children}</main>
  </div>
)

export default Layout
